import React from "react";
import styled from "styled-components";
import { Text } from "../components";
import { useRouter } from "next/router";
import { ErrorPage } from "../components/ErrorPage";
import { getLayout } from "../components/Layout/CenterDialog";
import { Box } from "../components/Box";

const Location = styled(Text)`
  width: 100%;
  font-size: 1.2rem;
  font-family: monospace;
  background: ${({ theme }) => theme.color.background};
  padding: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  word-break: break-all;
  line-height: 2;
`;

const FourOhFour = () => {
  const router = useRouter();

  return (
    <ErrorPage
      title="Page Not Found"
      subtitle="404"
      button={{
        onClick: router.back,
        children: "Back",
      }}
    >
      <Box flexDirection="column" align="flex-start">
        <Text>Uh oh, this page doesn&apos;t exist:</Text>
        <Location>{router.asPath}</Location>
      </Box>
    </ErrorPage>
  );
};

FourOhFour.getLayout = getLayout;

export default FourOhFour;
