import styled from "styled-components";
import { Card } from "./Card";
import { EmojiFrown } from "@styled-icons/bootstrap";
import { Button } from "@questdb/react-components";
import { Undo } from "./icons/undo";
import Link from "next/link";
import React from "react";

const Root = styled.div`
  min-width: 500px;
  max-width: 600px;
  display: grid;
  gap: 1rem;
`;

const Content = styled(Card.Content)`
  padding: 4rem 2rem;
`;

const Footer = styled(Card.Footer)`
  padding: 2rem;
`;

const footerLinks = [
  {
    href: "https://slack.questdb.io",
    label: "Support on Slack",
  },
  {
    href: "mailto:support@questdb.io",
    label: "Support on Email",
  },
  {
    href: "https://questdb.io/docs/",
    label: "Docs",
  },
];

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  color: #838383;
`;

export type Props = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  button?: React.ComponentProps<typeof Button>;
  icon?: React.ReactNode;
};

export const ErrorPage = ({
  title,
  subtitle,
  children,
  button = {},
  icon,
}: Props) => (
  <Root>
    <Card>
      <Card.Header
        afterTitle={icon ?? <EmojiFrown size={subtitle ? 36 : 24} />}
        title={title}
        subtitle={subtitle}
      />

      <Content>{children}</Content>

      <Footer align="center">
        <Button
          skin="secondary"
          prefixIcon={<Undo size="18" />}
          type="button"
          {...button}
        >
          {button.children ?? "Back"}
        </Button>
      </Footer>
    </Card>

    <FooterLinks>
      {footerLinks.map(({ href, label }, index) => (
        <React.Fragment key={index}>
          <Link key={href} href={href}>
            {label}
          </Link>
          {index < footerLinks.length - 1 && "·"}
        </React.Fragment>
      ))}
    </FooterLinks>
  </Root>
);
